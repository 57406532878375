import React, { useContext, useEffect, useState } from "react";
import { debounce, startCase } from "lodash";

import io from "socket.io-client";

const context = React.createContext<{
  registerCallback: (fn: VoidFunction) => void;
  removeCallback: (fn: VoidFunction) => void;
}>(null);

function createHandler() {
  let callbacks = [];

  // We debounce the function because there can be a lot of events in short time which would otherwise trigger many refetches
  const executeCallback = debounce((event) => {
    if (Notification.permission === "granted") {
      new Notification("Account update: " + startCase(event.type));
    }
    callbacks.forEach((fn) => fn(event));
  }, 1500);

  // Googles Cloud Run just supports long polling
  const socket = io(process.env.REACT_APP_REMOTE_URL, {
    transports: ["polling"],
  });

  socket.on("change", executeCallback);

  return {
    registerCallback: (fn: VoidFunction) => {
      callbacks.push(fn);
    },
    disconnect: () => socket.disconnect(),
    removeCallback: (fn: VoidFunction) => {
      callbacks = callbacks.filter((cb) => cb !== fn);
    },
  };
}

export function useLiveRefresh(refreshFn: VoidFunction) {
  const handler = useContext(context);

  useEffect(() => {
    if (handler) {
      handler.registerCallback(refreshFn);
      return () => {
        handler.removeCallback(refreshFn);
      };
    }
  }, [handler, refreshFn]);

  return;
}

export function EventStreamContext({ children }: React.Props<{}>) {
  const [handler, setHandler] = useState(null);

  useEffect(() => {
    setHandler(() => createHandler());
    return () => {
      handler?.disconnect();
    };
    // eslint-disable-next-line
  }, []);

  return <context.Provider value={handler}>{children}</context.Provider>;
}
