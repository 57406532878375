import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  useCardsQuery,
  useRegisterUserMutation,
} from "../graphql/__generated__/graphql_types";

import { useIntl } from "react-intl";

export function RegistrationPage() {
  const { formatMessage: t } = useIntl();

  const [form, setForm] = useState({
    termsConditions: false,
    email: "",
    phone: "",
    name: "",
    password: "",
    passwordRepeat: "",
  });

  const history = useHistory();

  const [mutate] = useRegisterUserMutation();

  const params = new URLSearchParams(window.location.search);

  function updateField(prop: string) {
    return (evt) => {
      setForm({ ...form, [prop]: evt.target.value });
    };
  }

  let skip = !params.get("sr");

  const { data } = useCardsQuery({
    skip: skip,
    variables: {
      shopperReference: params.get("sr"),
    },
  });

  useEffect(() => {
    if (data?.card?.phone) {
      setForm((form) => ({ ...form, phone: data?.card?.phone }));
    }
  }, [data]);

  async function register(evt: React.SyntheticEvent) {
    evt.preventDefault();
    try {
      await mutate({ variables: form });

      if (data?.card) {
        history.push("/dashboard");
      } else {
        history.push("/registration/card-linking");
      }
    } catch (error) {
      alert(error.message);
    }
  }

  const card = data?.card;

  return (
    <div className="row">
      <div className="sixteen wide column ">
        <div className="text-center">
          <div className="col center">
            <form className="ui form" action="" style={{ marginTop: 20 }}>
              <div className="three fields ">
                <div className="field">
                  <input
                    placeholder={t({ id: "register.name" })}
                    type="text"
                    onChange={updateField("name")}
                  />
                </div>
                <div className="field">
                  <input
                    placeholder="E-mail"
                    type="text"
                    onChange={updateField("email")}
                  />
                </div>

                <div className={`${card ? "disabled" : ""} field`}>
                  <input
                    value={form.phone}
                    placeholder={t({ id: "register.phone" })}
                    type="text"
                    onChange={updateField("phone")}
                  />
                </div>
              </div>
              <div className="two fields">
                <div className="field">
                  <input
                    v-model="form.password"
                    placeholder={t({ id: "register.password" })}
                    type="password"
                    onChange={updateField("password")}
                  />
                </div>
                <div className="field">
                  <input
                    placeholder={t({ id: "register.confirm" })}
                    type="password"
                    onChange={updateField("passwordRepeat")}
                  />
                </div>
              </div>

              <div className="field">
                <div className="ui checkbox">
                  <input
                    type="checkbox"
                    checked={form.termsConditions}
                    onClick={() =>
                      setForm({
                        ...form,
                        termsConditions: !form.termsConditions,
                      })
                    }
                  />
                  <label
                    onClick={() =>
                      setForm({
                        ...form,
                        termsConditions: !form.termsConditions,
                      })
                    }
                  >
                    <p>{t({ id: "register.acceptTerms" })}</p>
                  </label>
                </div>
              </div>

              <button
                className="ui big button primary"
                type="submit"
                onClick={register}
              >
                {t({ id: "register.button.create" })}
              </button>
              <div className="ui horizontal divider">
                {t({ id: "login.or" })}{" "}
              </div>
              <Link className="ui small basic button" to="/login">
                {t({ id: "register.button.login" })}
              </Link>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
