import React from "react";

const ErrorComponent = (props: any) => {
  return (
    <>
      <h1>Something went wrong..</h1>
      <h5>{props.error.message}</h5>
    </>
  );
};

export default ErrorComponent;
