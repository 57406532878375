import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";

export const createPaymentKoekemannetje = gql`
  mutation CreatePaymentKoekemannetje($amount: Int!) {
    pos_startPaymentKoekemannetje(amount: $amount) {
      payment
    }
  }
`;

export function FakePOSKoekemannetje() {
  const [state, setState] = useState({
    loading: false,
    amount: 31,
    paymentResponse: "",
  });

  const [createPayment] = useMutation(createPaymentKoekemannetje);

  async function initiatePayment() {
    try {
      setState({ ...state, loading: true });

      const { data } = await createPayment({
        variables: {
          amount: state.amount,
        },
      });

      console.log({ data });

      setState({
        ...state,
        paymentResponse: data.pos_startPaymentKoekemannetje.payment,
        loading: false,
      });
    } catch (error) {
      setState({ ...state, loading: false });
      alert("Error on payment. Is the terminal online and cloud ready?");
    }
  }

  return (
    <div className="ui centered stackable padded relaxed grid">
      <div className="row">
        <div>
          <div className="ui header">
            Trigger Terminal for Koekemannetje demo
          </div>

          <div className="ui form">
            <div className="field">
              <label htmlFor="paymentAmount">Amount in cents</label>
              <input
                id="paymentAmount"
                type="number"
                min="10"
                value={state.amount}
                onChange={({ target: { value } }) =>
                  setState({ ...state, amount: Number(value) })
                }
                style={{ textAlign: "center" }}
              />
            </div>

            <div
              className={`ui primary ${state.loading ? "loading" : ""} button`}
              onClick={initiatePayment}
            >
              Initiate Payment for {state.amount / 100}€
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        {state.paymentResponse && !state.loading && (
          <div>{state.paymentResponse}</div>
        )}
      </div>
    </div>
  );
}
