import React, { useState } from "react";

import { useCreatePaymentPayNlMutation } from "../graphql/__generated__/graphql_types";
import { useHistory } from "react-router";

export function FakePOSPAYNL() {
  const history = useHistory();

  const [state, setState] = useState({
    loading: false,
    amount: 1,
    paymentResponse: "",
  });

  const [createPaymentFunction] = useCreatePaymentPayNlMutation();

  async function initiatePayment() {
    try {
      setState({ ...state, loading: true });

      const paymentResult = await createPaymentFunction({
        variables: {
          amount: state.amount,
        },
      });

      setState({
        ...state,
        loading: false,
      });

      console.log(paymentResult.data.pos_startPayment);

      history.push(
        `/pos/paynl/${paymentResult.data.pos_startPayment.transactionId}?hash=${paymentResult.data.pos_startPayment.hash}`
      );
    } catch (error) {
      setState({ ...state, loading: false });
      alert("Error on payment. Is the terminal online and cloud ready?");
    }
  }

  return (
    <div className="ui centered stackable padded relaxed grid">
      <div className="row">
        <div>
          <div className="ui header">Terminal PayNL</div>

          <div className="ui form">
            <div className="field">
              <label htmlFor="paymentAmount">Amount in cents</label>
              <input
                id="paymentAmount"
                type="number"
                min="10"
                value={state.amount}
                onChange={({ target: { value } }) =>
                  setState({ ...state, amount: Number(value) })
                }
                style={{ textAlign: "center" }}
              />
            </div>

            <div
              className={`ui primary ${state.loading ? "loading" : ""} button`}
              onClick={initiatePayment}
            >
              Initiate Payment for {state.amount / 100}€
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
