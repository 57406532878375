import { Link, useHistory } from "react-router-dom";
import React, { useState } from "react";

import { useIntl } from "react-intl";
import { useLoginUserMutation } from "../graphql/__generated__/graphql_types";

export function LoginPage() {
  const { formatMessage: t } = useIntl();
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();

  const history = useHistory();

  const [mutate] = useLoginUserMutation();

  async function login(evt) {
    evt.preventDefault();
    try {
      await mutate({ variables: { email, password } });
      history.push("/dashboard");
    } catch (error) {
      alert(error.message);
    }
  }

  return (
    <div className="ui centered stackable padded relaxed grid dashboard">
      <div className="row" style={{ marginTop: 20 }}>
        <img
          src={require("../assets/N.png")}
          style={{ width: 100, maxHeight: 100, objectFit: "contain" }}
          alt="Nikkie logo"
        />
      </div>

      <div className="row">
        <div className="five wide column" style={{ marginTop: 10 }}>
          <div className="text-center">
            <div className="col center">
              <form action="" className="ui form">
                <div className="field">
                  <input
                    placeholder="E-mail"
                    type="text"
                    onChange={({ target: { value } }) => setEmail(value)}
                    autoComplete="current-password"
                  />
                </div>
                <div className="field">
                  <input
                    placeholder={t({ id: "login.password" })}
                    type="password"
                    onChange={({ target: { value } }) => setPassword(value)}
                    autoComplete="current-password"
                  />
                </div>
                <button className="ui big button primary" onClick={login}>
                  Login
                </button>
              </form>
            </div>

            <div className="ui horizontal divider">
              {t({ id: "login.or" })}{" "}
            </div>
            <Link className="ui small basic button" to="/registration">
              {t({ id: "login.register" })}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
