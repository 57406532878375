declare var AdyenCheckout;

export const css = (text: any) => text[0];
export const html = (text: any) => text[0];

async function waitUntilLoaded() {
  return new Promise((res) => {
    const int = setInterval(() => {
      if ((window as any).AdyenCheckout) {
        clearInterval(int);
        res();
      }
    }, 500);
  });
}

const originKeys = {
  "nikkie-dev.loyyo.nl":
    "pub.v2.8015616616893921.aHR0cHM6Ly9uaWtraWUtZGV2LmxveXlvLm5s.WsrdFqrCjbeu3IltEAdTAjJ2AqQJXa-lehz9w6GrWME",
  "nikkie.loyyo.nl":
    "pub.v2.4115686549223817.aHR0cHM6Ly9uaWtraWUubG95eW8ubmw.1zWYmb0XcFa4-RXvz8jz8ulIDerezfNdlJIEEVqozMA",
  "localhost:1234":
    "pub.v2.8015616616893921.aHR0cDovL2xvY2FsaG9zdDoxMjM0.h6I-p5mrSD3SLPIN0SeP62I0lZZ5kHN9L0vnj8gMd-U",
  "localhost:3000":
    "pub.v2.8015616616893921.aHR0cDovL2xvY2FsaG9zdDozMDAw.JG7b2qM7CRG2mfrKnKwmfnGUBz6Y_rY4QWxW6tkzBGA",
};

export async function setupAdyen(
  handleChange,
  paymentMethods,
  validateAppleMerchant,
  {
    amount = 2,
    type,
    mountElement = "#payment",
    getAmount,
    environment,
  }: {
    userId?: string;
    amount?: number;
    type: "ideal" | "card" | "applepay";
    mountElement: string;
    getAmount?: () => number;
    environment: "demo" | "live";
  }
) {
  console.log("configuration");

  await waitUntilLoaded();

  const configuration = {
    locale: navigator.language,
    environment: environment === "demo" ? "test" : "live",
    originKey: originKeys[window.location.host],
    paymentMethodsResponse: paymentMethods,
  };

  console.log(configuration);

  const checkout = new AdyenCheckout(configuration);

  const paymentElement = document.querySelector("#payment");

  paymentElement.innerHTML = "";

  // Add a css class for speficic styling
  paymentElement.classList.add(`payment-${type}`);
  // If we click through the payment methods we should remove the old one
  paymentElement.classList.remove(
    `payment-${type === "ideal" ? "card" : "ideal"}`
  );

  if (type === "applepay") {
    const config = {
      currencyCode: "EUR",
      amount,
      countryCode: "NL",
      configuration: {
        merchantName: "PuntjeEcom",
        merchantIdentifier: "merchant.com.adyen.puntje.test",
      },
      buttonType: "plain",
      // buttonColor: "white-with-line",
      supportedNetworks: ["maestro", "masterCard", "visa"],
      version: 4,
      onValidateMerchant: async (
        resolve: (data) => void,
        reject: VoidFunction,
        validationURL: string
      ) => {
        const data = await validateAppleMerchant(validationURL);
        data.epochTimestamp = Number(data.epochTimestamp);
        data.expiresAt = Number(data.expiresAt);
        resolve(data);
      },
      onPaymentMethodSelected: (resolve) => {
        resolve({
          newTotal: {
            label: "Loyyio Payment",
            amount: String((getAmount ? getAmount() : amount) / 100),
            type: "final",
          },
        });
      },
      onChange: (paymentMethod) => {
        handleChange(paymentMethod);
      },
    };

    const applepay = checkout.create(type, config);

    applepay
      .isAvailable()
      .then(() => {
        applepay.mount(mountElement);
      })
      .catch(() =>
        alert(
          Boolean((window as any).safari)
            ? "ApplePay is not available"
            : "ApplePay is not available in this browser. Please open this page in Safari."
        )
      );
  } else {
    const config = {
      onChange: (paymentMethod) => {
        handleChange(paymentMethod);
      },
    };
    checkout.create(type, config).mount(mountElement);
  }
}
