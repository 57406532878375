import React, { Fragment, useState } from "react";

import _ from "lodash";
import { useActivityQuery } from "../graphql/__generated__/graphql_types";
import { useIntl } from "react-intl";
import { useLiveRefresh } from "./services/EventStreamContext";

function formatEventTitle(eventType: string, data: string, t: any): string {
  const info = JSON.parse(data);
  switch (eventType) {
    case "PointsAdded": {
      return t({ id: "activity.points.added" }, { points: info.points });
    }
    case "WelcomePoints": {
      return t({ id: "activity.welcomePoints" }, { points: info.points });
    }
    case "CardVerified": {
      return (
        _.startCase(info.card.paymentMethod) +
        t({ id: "activity.card.verified" })
      );
    }
    case "CouponCreated": {
      return t(
        { id: "activity.coupon.created" },
        { coupon: info.coupon.amountInCent / 100 }
      );
    }
    default: {
      return _.startCase(eventType);
    }
  }
}

export function ActivityPage() {
  const { formatMessage: t } = useIntl();

  const [selectedEventType, setSelectedEventType] = useState<any>(null);

  const { data, loading, refetch } = useActivityQuery({
    fetchPolicy: "no-cache",

    variables: {
      types: selectedEventType ? [selectedEventType] : null,
    },
  });

  useLiveRefresh(refetch);

  if (loading) {
    return <div className="ui active loader"></div>;
  }

  if (!data) {
    return null;
  }

  return (
    <Fragment>
      <div v-if="profile" className="row padded-row">
        <div className="four wide column">
          <h3 className="ui header">
            <i className="large bell outline icon"></i>
            Recente activiteit
          </h3>

          <ul className="ui relaxed divided list">
            {data.me.eventsCollection.items.map((event, ix) => (
              <div key={ix} className="item">
                <div className="content">
                  <div className="header" style={{ cursor: "auto" }}>
                    {formatEventTitle(event.type, event.data, t)}
                  </div>
                  <div className="description">
                    <span style={{ minWidth: 210, display: "inline-block" }}>
                      {t(
                        { id: "activity.date" },
                        { actDate: new Date(event.timestamp) }
                      )}{" "}
                      {t(
                        { id: "activity.time" },
                        { actTime: new Date(event.timestamp) }
                      )}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </ul>
        </div>

        <div className="four wide column">
          {data &&
            data.me.eventsCollection.eventSet.sort().map((eventType, ix) => (
              <div key={ix} style={{ margin: 5 }}>
                <button
                  style={{
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor:
                      selectedEventType === eventType ? "black" : "silver",
                    padding: 5,
                    margin: 5,
                  }}
                  onClick={() => {
                    selectedEventType === eventType
                      ? setSelectedEventType(null)
                      : setSelectedEventType(eventType);
                  }}
                >
                  {_.startCase(eventType)}
                </button>
              </div>
            ))}
        </div>
      </div>
    </Fragment>
  );
}
