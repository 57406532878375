import React from "react";

const LoadingSpinner = () => {
  return (
    <>
      <div
        className="ui active centered inline loader"
        style={{ marginTop: 100 }}
      ></div>
    </>
  );
};

export default LoadingSpinner;
