import "../styles/default-theme.css";

import { Link, useHistory } from "react-router-dom";
import React, { Fragment, useEffect } from "react";
import {
  useDashboardQuery,
  useLinkCardMutation,
} from "../graphql/__generated__/graphql_types";

import { Tab } from "semantic-ui-react";
import { startCase } from "lodash";
import { useIntl } from "react-intl";
import { useLiveRefresh } from "./services/EventStreamContext";

export function DashboardPage() {
  const { formatMessage: t } = useIntl();

  const history = useHistory();

  const { data, loading, refetch } = useDashboardQuery();

  const [mutate] = useLinkCardMutation();

  useLiveRefresh(refetch);

  useEffect(() => {
    async function linkCard(shopperReference: string) {
      try {
        const result = await mutate({
          variables: {
            shopperReference,
          },
        });
        const message = result.data.linkCardToAccount;
        if (message.startsWith("Error")) {
          alert(message);
        }
      } catch (error) {
        alert(error.message);
      }
    }

    const params = new URLSearchParams(window.location.search);

    if (params.get("sr")) {
      linkCard(params.get("sr"));
    }

    history.push("/dashboard");
  }, [mutate, history]);

  if (loading) {
    return <div v-if="loading" className="ui active loader"></div>;
  }

  if (!data) {
    return null;
  }

  function activateNotifications() {
    if (window.Notification?.permission !== "denied") {
      window.Notification?.requestPermission();
    }
  }

  async function logout() {
    await fetch(process.env.REACT_APP_REMOTE_URL + "/api/client/logout", {
      credentials: "include",
    });
    window.location.reload();
  }

  const showNotificationsButton = window.Notification?.permission === "default";
  const notificationDenied = window.Notification?.permission === "denied";

  const panes = [
    {
      menuItem: {
        icon: "bell outline",
        key: "Activity",
        className: "tab-icon",
      },
      render: () => (
        <Tab.Pane className="tab-content-container mobile-tab-content-container">
          <h3 className="ui header tab-title">
            {t({ id: "dashboard.recent.events" })}
          </h3>
          <ul className="ui list dashboard-list">
            {data.me.events.slice(0, 4).map((event, ix) => (
              <div key={event.id + ix} className="item">
                <div className="content">
                  <div className="header">
                    {startCase(event.type) || "unknown"}
                  </div>
                  <div className="tab-text-description">
                    <span>
                      {t(
                        { id: "dashboard.events.subtitle" },
                        { date: new Date(event.timestamp) }
                      )}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </ul>

          <Link
            id="tab-button"
            className="ui basic button"
            to="/dashboard/activity"
          >
            {t({ id: "dashboard.showMore.events" })}
          </Link>
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        icon: "euro sign",
        key: "Coupons",
        className: "tab-icon",
      },
      render: () => (
        <Tab.Pane className="tab-content-container  mobile-tab-content-container">
          <h3 className="ui header tab-title">
            {t({ id: "dashboard.coupons.title" })}
          </h3>
          <ul className="ui list dashboard-list">
            {data.me.coupons.slice(0, 4).map((coupon) => (
              <div key={coupon.code} className="item">
                <div className="content">
                  <div className="header">
                    Code <span className="coupon-code">{coupon.code}</span> van{" "}
                    {(coupon.amountInCent / 100).toLocaleString()}€
                  </div>
                  <div className="tab-text-description">
                    <span>
                      {coupon.redeemedAt ? (
                        <span>
                          {t(
                            { id: "dashboard.coupons.date.redeemed" },
                            { date: new Date(coupon.redeemedAt) }
                          )}{" "}
                          {t(
                            { id: "dashboard.coupons.time.redeemed" },
                            { time: new Date(coupon.redeemedAt) }
                          )}
                        </span>
                      ) : (
                        <span>
                          {t(
                            { id: "dashboard.coupons.date.created" },
                            { date: new Date(coupon.createdAt) }
                          )}{" "}
                          {t(
                            { id: "dashboard.coupons.time.created" },
                            { time: new Date(coupon.createdAt) }
                          )}
                        </span>
                      )}
                    </span>
                    {coupon.redeemed ? (
                      <div className="ui yellow horizontal label">
                        {t({ id: "dashboard.coupons.redeemed" })}
                      </div>
                    ) : (
                      <div
                        v-if="!coupon.redeemed"
                        className="ui green horizontal label"
                      >
                        {t({ id: "dashboard.coupons.active" })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </ul>

          {!data.me.coupons.length && (
            <div className="item-description">
              {" "}
              {t({ id: "dashboard.geen.coupons" })}!
            </div>
          )}

          <Link className="ui basic button" to="/dashboard/coupons">
            {t({ id: "dashboard.coupons.showMore" })}
          </Link>
        </Tab.Pane>
      ),
    },

    {
      menuItem: {
        icon: "shopping cart",
        key: "Webshop",
        className: "tab-icon",
      },
      render: () => (
        <Tab.Pane className="tab-content-container mobile-tab-content-container">
          <h3 className="ui header tab-title">
            {t({ id: "dashboard.save.webshop" })}
          </h3>

          <div className="subheader">
            {t({ id: "dashboard.save.description" })}
          </div>

          <ul className="ui list dashboard-list">
            {data.me.ecomEmails.map((ecomEmail) => (
              <div key={ecomEmail.email} className="item">
                <div className="content email">
                  <div className="header">{ecomEmail.email}</div>
                </div>
              </div>
            ))}
          </ul>
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        icon: "credit card outline",
        key: "Stores",
        className: "tab-icon",
      },
      render: () => (
        <Tab.Pane className="tab-content-container mobile-tab-content-container">
          <h3 className="ui header tab-title">
            {t({ id: "dashboard.saveStore.title" })}
          </h3>

          <div className="subheader">
            {t({ id: "dashboard.saveStore.description" })}
          </div>

          <ul className="ui list dashboard-list">
            {data.me.cards.map((card) => (
              <div key={card.shopperReference} className="item">
                <div className="content">
                  <div className="header">
                    {card.paymentMethod.toUpperCase() || "unknown"}
                    {" with "}
                    {card.provider} (...
                    {JSON.parse(card.cardInfos)?.cardSummary})
                  </div>
                  <div className="tab-text-description">
                    <span>
                      {new Date(card.createdAt).toLocaleString()} on{" "}
                      {card.registrationStrategy || ""}
                    </span>{" "}
                    {card.verified ? (
                      <div className="ui green horizontal label">
                        {t({ id: "dashboard.saveStore.verified" })}
                      </div>
                    ) : (
                      <div className="ui red horizontal label">
                        {t({ id: "dashboard.saveStore.notVerified" })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </ul>

          <Link className="ui basic button" to="/dashboard/connect-new-card">
            {t({ id: "dashboard.saveStore.connectNew" })}
          </Link>
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        icon: "setting",

        key: "Settings",

        className: "tab-icon",
      },

      render: () => (
        <Tab.Pane className="tab-content-container mobile-tab-content-container">
          <h3 className="ui header tab-title">
            {t({ id: "navbar.settings" })}
          </h3>

          <ul className="ui list dashboard-list">
            <div className="item-description">
              Your notifications will be send to{" "}
              <strong>
                {(data.me as any).notificationStrategy === "Email"
                  ? data.me.email
                  : data.me.phone}
              </strong>
            </div>
          </ul>

          <Link className="tab-link" to="/dashboard/settings">
            {t({ id: "settings.change" })}
          </Link>

          <i className="chevron right icon chevron-icon small" />
        </Tab.Pane>
      ),
    },
  ];
  const TabsDashboard = () => (
    <Tab menu={{ fluid: true, vertical: true, tabular: true }} panes={panes} />
  );

  return (
    <Fragment>
      {data.me.name && (
        <div className="welcome-name"> Welcome, {data.me.name} </div>
      )}

      <div className="row padded-row  mobile-tab-row">
        <TabsDashboard />
      </div>

      <div className="row">
        <div className="ui small basic stackable buttons">
          <div onClick={logout} className="ui mini button">
            Log out
          </div>

          {data && data.me.isAdmin && (
            <React.Fragment>
              <Link className="ui mini button" to="/admin">
                Admin
              </Link>
              <Link className="ui mini button" to="/pos">
                POS Adyen
              </Link>
              <Link className="ui mini button" to="/pos/paynl">
                POS Pay NL
              </Link>
            </React.Fragment>
          )}
        </div>
      </div>

      {showNotificationsButton && (
        <div className="row">
          <div className="five wide column dashboard-row">
            <button
              className="ui basic blue button"
              onClick={activateNotifications}
            >
              {t({ id: "dashboard.button.activateNotifications" })}
            </button>
          </div>
        </div>
      )}

      {notificationDenied && (
        <div className="row">
          <div className="five wide column" style={{ textAlign: "center" }}>
            {t({ id: "dashboard.button.funnierMessage" })}
          </div>
        </div>
      )}
    </Fragment>
  );
}
