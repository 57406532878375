/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CardProvider {
  Adyen = "Adyen",
  PayNL = "PayNL",
}

export enum NotificationStrategy {
  Email = "Email",
  Phone = "Phone",
  Push = "Push",
}

export interface CreateRegisterPaymentInput {
  paymentMethod: PaymentMethodInput;
  returnURL: string;
}

export interface PaymentMethodInput {
  type?: string | null;
  applepayToken?: string | null;
  issuer?: string | null;
  schema?: string | null;
  encryptedCardNumber?: string | null;
  encryptedExpiryMonth?: string | null;
  encryptedExpiryYear?: string | null;
  encryptedSecurityCode?: string | null;
}

export interface UpdateNotificationStrategyInput {
  pushKey?: string | null;
  phone?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
