import "./App.css";

import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import React, { Fragment, PropsWithChildren, useEffect, useState } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
} from "react-router-dom";

import { ActivityPage } from "./features/ActivityPage";
import { AdminRouter } from "./features/admin/src/Routes";
import { AwaitCard } from "./features/AwaitCard";
import { AwaitCardDashboard } from "./features/AwaitCardDashboard";
import { CardLinkingDecisionPage } from "./features/CardLinkingDecisionPage";
import { CardLinkingPage } from "./features/CardLinkingPage";
import { ConnectNewCard } from "./features/ConnectNewCard";
import { CouponsPage } from "./features/CouponsPage";
import { DashboardLayout } from "./features/components/DashboardLayout";
import { DashboardPage } from "./features/DashboardPage";
import { EventStreamContext } from "./features/services/EventStreamContext";
import { FakePOS } from "./features/FakePOS";
import { FakePOSPAYNL } from "./features/FakePOSPayNL";
import FakePOSPayNLPayment from "./features/FakePOSPayNLPayment";
import { IntlProvider } from "react-intl";
import { LoginPage } from "./features/LoginPage";
import NotFoundPage from "./features/admin/src/pages/NotFoundPage";
import { PointsRedeemer } from "./features/PointsRedeemer";
import { RegistrationLayout } from "./features/components/RegistrationLayout";
import { RegistrationPage } from "./features/RegistrationPage";
import { SettingsPage } from "./features/SettingsPage";
import axios from "axios";
import { translations } from "./features/i18messages";
import { FakePOSKoekemannetje } from "./features/FakePOSKoekemannetje";

const client = new ApolloClient({
  uri: process.env.REACT_APP_REMOTE_URL + "/graphql",
  cache: new InMemoryCache(),
  credentials: "include",
});

function DashboardRouter() {
  return (
    <LoginGuard>
      <EventStreamContext>
        <DashboardLayout>
          <Switch>
            <Route path="/dashboard/" exact component={DashboardPage} />

            <Route path="/dashboard/coupons" component={CouponsPage} />
            <Route path="/dashboard/activity" component={ActivityPage} />
            <Route path="/dashboard/settings" component={SettingsPage} />
            <Route
              path="/dashboard/connect-new-card"
              component={ConnectNewCard}
            />
            <Route
              path="/dashboard/await-card"
              component={AwaitCardDashboard}
            />
          </Switch>
        </DashboardLayout>
      </EventStreamContext>
    </LoginGuard>
  );
}

function RegistrationRouter() {
  return (
    <RegistrationLayout>
      <Switch>
        <Route path="/registration/" exact component={RegistrationPage} />
        <Route path="/registration/await-card" component={AwaitCard} />
        <Route path="/registration/card-linking" component={CardLinkingPage} />
      </Switch>
    </RegistrationLayout>
  );
}

function LoginGuard({ children }: PropsWithChildren<{}>) {
  const history = useHistory();

  const [allowed, setAllowed] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    axios(process.env.REACT_APP_REMOTE_URL + "/api/user", {
      withCredentials: true,
    })
      .then(({ data }) => {
        if (data) {
          setAllowed(true);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          history.push("/login");
        } else {
          setError(error.message);
        }
      });
  }, [history]);

  if (error) {
    return (
      <div style={{ color: "red", textAlign: "center", marginTop: 50 }}>
        {error}
      </div>
    );
  }

  return <Fragment>{allowed ? children : null}</Fragment>;
}

function App() {
  return (
    <div>
      <IntlProvider
        locale={navigator.language}
        defaultLocale="en"
        messages={translations[navigator.language] || translations.en}
      >
        <ApolloProvider client={client}>
          <Router>
            <Switch>
              <Route path="/dashboard" component={DashboardRouter} />
              <Route path="/registration" component={RegistrationRouter} />
              <Route path="/login" component={LoginPage} />
              <Route path="/points-redeemer" component={PointsRedeemer} />
              <Route path="/card-landing" component={CardLinkingDecisionPage} />

              <Route path="/pos" exact component={FakePOS} />
              <Route
                path="/pos/koekemannetje"
                exact
                component={FakePOSKoekemannetje}
              />
              <Route path="/pos/paynl" exact component={FakePOSPAYNL} />
              <Route
                path="/pos/paynl/:orderId"
                exact
                component={FakePOSPayNLPayment}
              />

              <Route exact path="/">
                <Redirect to="/dashboard" />
              </Route>

              <Route path="/client">
                <Redirect to="/dashboard" />
              </Route>

              <Route path="/admin" component={AdminRouter} />
              <Route path="*">
                <NotFoundPage />
              </Route>
            </Switch>
          </Router>
        </ApolloProvider>
      </IntlProvider>
    </div>
  );
}

export default App;
