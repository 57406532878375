import ErrorComponent from "../ErrorComponent/ErrorComponent";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import React from "react";
import { Table } from "semantic-ui-react";
import { useGetTransactionsQuery } from "../../graphql/__generated__/graphql_types";

const Transactions = () => {
  const { data, error, loading } = useGetTransactionsQuery();
  let transactions: any[] = [];

  if (loading) return <LoadingSpinner />;

  if (error) return <ErrorComponent error={error} />;

  if (data?.transactions) transactions = data.transactions;

  return (
    <>
      <h1>Transactions dashboard</h1>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Points</Table.HeaderCell>
            <Table.HeaderCell>Payment method</Table.HeaderCell>
            <Table.HeaderCell>Note</Table.HeaderCell>
            <Table.HeaderCell>Created At</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {transactions.map((item, index) => {
            return (
              <Table.Row key={index}>
                <Table.Cell>{item.ecomEmail?.email} </Table.Cell>
                <Table.Cell>{item.points} </Table.Cell>
                <Table.Cell>{item.card?.paymentMethod} </Table.Cell>
                <Table.Cell>{item.note} </Table.Cell>
                <Table.Cell>
                  {new Date(item.createdAt).toLocaleString()}{" "}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

export default Transactions;
