import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  useCreateRegisterPaymentMutation,
  usePaymentMethodsQuery,
  useUserQuery,
  useValidateAppleMerchantMutation,
} from "../graphql/__generated__/graphql_types";

import { Dropdown } from "semantic-ui-react";
import queryString from "querystring";
import { setupAdyen } from "./services/setupAdyen";

function getPaymentEnvironment(): Promise<"demo" | "live"> {
  return fetch(
    process.env.REACT_APP_REMOTE_URL + "/api/payment-mode"
  ).then((res) => res.json());
}

function loadAdyenLib() {
  return getPaymentEnvironment().then((mode) => {
    var script = document.createElement("script");

    script.src =
      mode === "demo"
        ? "https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.3.0/adyen.js"
        : "https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/3.3.0/adyen.js";

    document.head.appendChild(script); //or something of the likes

    return mode;
  });
}

export function CardLinkingPage() {
  const { data: paymentMethodsResult } = usePaymentMethodsQuery({
    variables: { amountInCent: 2 },
  });

  const [validateMerchant] = useValidateAppleMerchantMutation();

  const [startPayment] = useCreateRegisterPaymentMutation();

  const history = useHistory();

  const { data: user, loading } = useUserQuery();

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const [state, setState] = useState<{
    paymentType: null | "ideal" | "card" | "applepay";
    paymentMethodDetails: any;
  }>({ paymentType: "ideal", paymentMethodDetails: null });

  useEffect(() => {
    loadAdyenLib();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("shopperReference") && params.get("resultCode")) {
      history.push(`/registration/await-card${window.location.search}`);
    }
  }, [history]);

  useEffect(() => {
    if (paymentMethodsResult) {
      getPaymentEnvironment().then((mode) => {
        setupAdyen(
          (paymentMethod) => {
            state.paymentMethodDetails = paymentMethod.data.paymentMethod;
            if (state.paymentType === "applepay") {
              startPayment();
            }
          },
          paymentMethodsResult.paymentMethods,
          async (url: string) => {
            const { data } = await validateMerchant({
              variables: {
                validationURL: url,
              },
            });
            return data.validateAppleMerchant;
          },
          {
            amount: 2,
            getAmount: () => 2,
            mountElement: "#payment",
            type: state.paymentType,
            environment: mode,
          }
        );
      });
    }
  }, [
    paymentMethodsResult,
    state.paymentType,
    startPayment,
    state.paymentMethodDetails,
    validateMerchant,
  ]);

  async function handleStartPayment() {
    if (!state.paymentMethodDetails) {
      return;
    }
    if (state.paymentMethodDetails["applepay.token"]) {
      state.paymentMethodDetails.applepayToken =
        state.paymentMethodDetails["applepay.token"];
      delete state.paymentMethodDetails["applepay.token"];
    }
    const separator = window.location.href.includes("?") ? "&" : "?";

    const { data } = await startPayment({
      variables: {
        input: {
          paymentMethod: state.paymentMethodDetails,
          returnURL:
            window.location.href +
            `${separator}paymentType=${state.paymentType}`,
        },
      },
    });

    const {
      resultCode,
      shopperReference,
      redirectURL,
    } = data.registerWithPayment;

    // An credit card/applepay payment which has no redirect but rather the success code comes in the ajax response
    if (resultCode && resultCode.toLowerCase() === "authorised") {
      const query = queryString.stringify({
        shopperReference,
        resultCode: "authorised",
        paymentType: state.paymentType,
      });
      window.location.href = window.location.href + `${separator}${query}`;
    } else {
      // An iDEAL payment
      window.location.href = redirectURL;
    }
  }

  if (loading) {
    return (
      <div
        className="ui active centered inline loader"
        style={{ marginTop: 20 }}
      ></div>
    );
  }

  if (!user) {
    return <div>This page can only be viewed with a user</div>;
  }

  return (
    <div>
      <link
        rel="stylesheet"
        href="https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.3.0/adyen.css"
      />
      <div className="ui stackable grid">
        <div className="centered two columns row" style={{ margin: 10 }}>
          <div className="four wide column">
            <p style={{ textAlign: "center", fontSize: "large" }}>
              <strong>Welcom {user?.me?.name}!</strong>
            </p>
            <p>Je spaart vanaf nu automatisch in onze webshop.</p>
            <p>
              Wil je ook automatisch in onze winkel sparen? Koppel dan jouw
              gewenste betaalmethode waarmee je betaalt in onze winkel. Je kunt
              er later meer toevoegen.
            </p>
          </div>
          <div className="five wide column">
            {Boolean(user.me.cards?.length) && (
              <React.Fragment>
                <p
                  v-if="user.cards && user.cards.length"
                  style={{ textAlign: "center", fontWeight: "bold" }}
                >
                  <br />
                  Je hebt al een pinpas (of ApplePay) gekoppeld.{" "}
                </p>
                <p style={{ textAlign: "center", fontWeight: "bold" }}>
                  <Link to="/dashboard" href="">
                    Ga naar het dashboard
                  </Link>
                </p>
                <div
                  v-if="user.cards && user.cards.length"
                  className="ui horizontal divider"
                >
                  of koppel nog een pas
                </div>
              </React.Fragment>
            )}
            <div className="group-wrapper">
              <Dropdown
                placeholder="Select Payment method"
                fluid
                selection
                value={state.paymentType}
                onChange={(evt, data) =>
                  setState({
                    ...state,
                    paymentType: data.value as "ideal" | "card" | "applepay",
                  })
                }
                options={[
                  { key: "ideal", text: "iDEAL", value: "ideal" },
                  { key: "cc", text: "Credit", value: "card" },
                  isSafari && {
                    key: "applepay",
                    text: "Apple Pay",
                    value: "applepay",
                  },
                ].filter(Boolean)}
              />
            </div>

            <div>
              <div id="payment" style={{ marginTop: 20, width: "100%" }}></div>
            </div>
          </div>
        </div>
        <div className="centered row">
          <div>
            <button
              v-if="state.paymentType !== 'applepay'"
              id="submit-register-payment"
              className="ui big button secondary cta"
              onClick={handleStartPayment}
            >
              Koppel {state.paymentType === "ideal" && <span>pinpas</span>}
              {state.paymentType === "card" && <span>kredietkaart</span>}
              {state.paymentType === "applepay" && <span>apple pay</span>}
            </button>

            <div style={{ marginTop: 5 }}>
              Koppelen gaat via een 2ct betaling
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
