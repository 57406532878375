import { Button, Table } from "semantic-ui-react";
import {
  useDeleteCardMutation,
  useGetCardsQuery,
} from "../../graphql/__generated__/graphql_types";

import ErrorComponent from "../ErrorComponent/ErrorComponent";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import React from "react";
import { sumBy } from "lodash";

const LinkedCards = () => {
  const { data, error, loading, refetch } = useGetCardsQuery();

  const [deleteCardMutation] = useDeleteCardMutation();

  let cards: any[] = [];

  if (loading) return <LoadingSpinner />;

  if (error) return <ErrorComponent error={error} />;

  if (data?.cards) {
    cards = data.cards;
  }

  const handleDeleteCard = async (id: string) => {
    await deleteCardMutation({ variables: { id } });
    refetch();
  };

  return (
    <>
      <h1>Cards dashboard</h1>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>User</Table.HeaderCell>
            <Table.HeaderCell>LoRence</Table.HeaderCell>
            <Table.HeaderCell># Transactions</Table.HeaderCell>
            <Table.HeaderCell>Total points</Table.HeaderCell>
            <Table.HeaderCell>Payment method</Table.HeaderCell>
            <Table.HeaderCell>Reg Strategy</Table.HeaderCell>
            <Table.HeaderCell>CardInfo</Table.HeaderCell>
            <Table.HeaderCell>Provider</Table.HeaderCell>
            <Table.HeaderCell>Verified</Table.HeaderCell>
            <Table.HeaderCell>Created At</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {cards.map((card, index) => {
            return (
              <Table.Row key={index}>
                <Table.Cell>{card.user?.email} </Table.Cell>
                <Table.Cell style={{ maxWidth: 125, lineBreak: "anywhere" }}>
                  {card.shopperReference}
                </Table.Cell>
                <Table.Cell>{card.transactions.length} </Table.Cell>
                <Table.Cell>{sumBy(card.transactions, "points")} </Table.Cell>
                <Table.Cell>{card.paymentMethod}</Table.Cell>
                <Table.Cell>{card.registrationStrategy} </Table.Cell>
                <Table.Cell style={{ maxWidth: 200, overflowX: "auto" }}>
                  {card.cardInfos}
                </Table.Cell>
                <Table.Cell>{card.provider}</Table.Cell>
                <Table.Cell>{String(card.verified)} </Table.Cell>
                <Table.Cell>
                  {new Date(card.createdAt).toLocaleString()}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Button
                    icon="trash"
                    onClick={() => handleDeleteCard(card.id)}
                  >
                    Delete
                  </Button>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

export default LinkedCards;
