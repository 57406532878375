import React, { Props, Fragment } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

export function RegistrationLayout({ children }: Props<{}>) {
  const { formatMessage: t } = useIntl();

  return (
    <Fragment>
      <div className="ui centered stackable padded relaxed grid">
        <div className="nav-row tablet computer only row">
          <div
            className="ui fluid three item secondary big menu"
            style={{ width: "100%" }}
          >
            <div className=" item" style={{ justifyContent: "center" }}>
              <Link to="/dashboard">
                <img
                  className="ui image"
                  style={{
                    height: 50,
                    objectFit: "cover",
                    alignSelf: "center",
                  }}
                  src={require("../../assets/N.png")}
                  alt="Nikkie Logo"
                />
              </Link>
            </div>
          </div>

          <div className="row">
            <img
              className="subheader"
              src={require("../../assets/header-image.png")}
              style={{ width: "100%", objectFit: "contain" }}
              alt="Nikkie loyalty steps header"
            />
          </div>

          <div className="centered tablet computer only row show-steps">
            <div className="eight wide column">
              <div className="ui three ordered steps">
                <div className="step">
                  <div className="content">
                    <div className="title">
                      {t({ id: "register.create.account" })}
                    </div>
                  </div>
                </div>
                <div className="step">
                  <div className="content">
                    <div className="title">
                      {t({ id: "register.link.payment" })}
                    </div>
                  </div>
                </div>
                <div className="step">
                  <div className="content">
                    <div className="title">
                      {t({ id: "register.verification" })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="nav-row fluid mobile only row">
          <div
            className="ui fluid three item secondary big menu"
            style={{ width: "100%" }}
          >
            <div className=" item" style={{ justifyContent: "center" }}>
              <Link to="/dashboard">
                <img
                  className="ui image"
                  style={{
                    height: 40,
                    objectFit: "cover",
                    alignSelf: "center",
                  }}
                  src={require("../../assets/N.png")}
                  alt="Nikkie Logo"
                />
              </Link>
            </div>
          </div>

          <div style={{ justifyContent: "center", marginBottom: 20 }}>
            <img
              className="ui image"
              src={require("../../assets/header-image.png")}
              style={{
                objectFit: "cover",
                display: "flex",
                alignSelf: "flex-end",
                height: "80px",
              }}
              alt="Nikkie loyalty steps header"
            />
          </div>

          <div className="centered ten wide column computer only row">
            <div className=" centered ten wide column">
              <div className="ui three ordered steps registration-steps">
                <div className="step">
                  <div className="content">
                    <div className="title">
                      {t({ id: "register.create.account" })}
                    </div>
                  </div>
                </div>
                <div className="step">
                  <div className="content">
                    <div className="title">
                      {t({ id: "register.link.payment" })}
                    </div>
                  </div>
                </div>
                <div className="step">
                  <div className="content">
                    <div className="title">
                      {t({ id: "register.verification" })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="centered mobile only row registration-title">
          <div> {t({ id: "register.create.account" })} </div>
        </div>

        <div className="ui centered stackable padded relaxed grid">
          {children}
        </div>
      </div>
    </Fragment>
  );
}
