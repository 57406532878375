import { Link } from "react-router-dom";
import React from "react";
import logo from "../assets/N.png";
import { useIntl } from "react-intl";

const alignment = {
  textAlign: "center",
  justifyContent: "center",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
};

export function CardLinkingDecisionPage() {
  const { formatMessage: t } = useIntl();
  return (
    <div>
      <div style={{ height: "10vh", ...(alignment as any) }}>
        <img src={logo} alt="logo" style={{ height: "60%" }} />
      </div>

      <div
        style={{
          ...(alignment as any),
          height: "45vh",
          backgroundColor: "#ffa1d5",
        }}
      >
        <div className="ui header">
          {t({ id: "card.decision.signUp.text" })}
        </div>
        <Link
          to={"/registration" + window.location.search}
          className="ui secondary large button"
        >
          {t({ id: "card.decision.signUp.button" })}
        </Link>
      </div>

      <div
        style={{
          ...(alignment as any),
          height: "45vh",
          backgroundColor: "#e159a3",
        }}
      >
        <div className="ui inverted header">
          {t({ id: "card.decision.logIn.text" })}
        </div>
        <div>
          <Link
            to={"/dashboard" + window.location.search}
            className="ui large white button"
            style={{ color: "black", backgroundColor: "white" }}
          >
            {t({ id: "card.decision.logIn.button" })}
          </Link>
        </div>
      </div>
    </div>
  );
}
