import React, { Fragment, useState } from "react";

import { sumBy } from "lodash";
import { useCouponsQuery } from "../graphql/__generated__/graphql_types";
import { useIntl } from "react-intl";
import { useLiveRefresh } from "./services/EventStreamContext";

export function CouponsPage() {
  const { formatMessage: t } = useIntl();
  const [isRedeemedState, setIsRedeemedState] = useState<boolean>(null);

  const { data, loading, refetch } = useCouponsQuery({
    fetchPolicy: "no-cache",
    variables: {
      redeemed:
        typeof isRedeemedState === "boolean" ? isRedeemedState : undefined,
    },
  });

  useLiveRefresh(refetch);

  const sumUnredeemed = data
    ? sumBy(
        data.me.couponsCollection.items.filter((coupon) => !coupon.redeemed),
        "amountInCent"
      )
    : 0;
  const sumRedeemed = data
    ? sumBy(
        data.me.couponsCollection.items.filter((coupon) => coupon.redeemed),
        "amountInCent"
      )
    : 0;

  if (loading) {
    return <div className="ui active loader"></div>;
  }

  if (!data) {
    return null;
  }

  return (
    <Fragment>
      <div className="row padded-row">
        <div className="five wide column">
          <h3 className="ui header">
            <i className="large euro sign icon"></i>
            {t({ id: "coupons.title" })}
          </h3>
          <ul v-if="profile.coupons.length" className="ui relaxed divided list">
            {data &&
              [true, false].map((isRedeemed, ix) => (
                <button
                  key={ix}
                  style={{
                    borderStyle: "solid",
                    borderWidth: 2,
                    borderColor:
                      isRedeemedState === isRedeemed ? "black" : "silver",
                    padding: 5,
                    margin: 5,
                  }}
                  onClick={() => {
                    isRedeemedState === isRedeemed
                      ? setIsRedeemedState(null)
                      : setIsRedeemedState(isRedeemed);
                  }}
                >
                  {" "}
                  {isRedeemed
                    ? t({ id: "coupons.button.redeemed" })
                    : t({ id: "coupons.button.active" })}
                </button>
              ))}

            {data.me.couponsCollection.items.map((coupon) => (
              <div
                key={coupon.code}
                className="item"
                v-for="coupon in profile.coupons"
              >
                <div className="content">
                  <div className="header">
                    {t(
                      { id: "coupons.list.item" },
                      {
                        couponCode: coupon.code,
                        couponValue: coupon.amountInCent / 100,
                      }
                    )}
                  </div>
                  <div className="description">
                    <span style={{ minWidth: 210, display: "inline-block" }}>
                      {coupon.redeemedAt ? (
                        <span>
                          {t(
                            { id: "coupons.redeemed.at" },
                            { date: new Date(coupon.redeemedAt) }
                          )}{" "}
                          {t(
                            { id: "coupons.redeemed.time" },
                            { time: new Date(coupon.redeemedAt) }
                          )}
                        </span>
                      ) : (
                        <span>
                          {t(
                            { id: "coupons.created.at" },
                            { date: new Date(coupon.createdAt) }
                          )}{" "}
                          {t(
                            { id: "coupons.created.time" },
                            { time: new Date(coupon.createdAt) }
                          )}
                        </span>
                      )}
                    </span>

                    {coupon.redeemed ? (
                      <div className="ui yellow horizontal label">
                        {t({ id: "coupons.status.redeemed" })}
                      </div>
                    ) : (
                      <div className="ui green horizontal label">
                        {t({ id: "coupons.status.active" })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </ul>
        </div>

        <div className="three wide column" style={{ textAlign: "center" }}>
          <div className="ui green statistic">
            <div className="value">
              {t(
                { id: "coupons.number.active" },
                { coupon: sumUnredeemed / 100 }
              )}
            </div>
            <div className="label">{t({ id: "coupons.active.subtitle" })}</div>
          </div>
          <br />

          <div className="ui yellow statistic">
            <div className="value">
              {t(
                { id: "coupons.number.redeemed" },
                { coupon: sumRedeemed / 100 }
              )}
            </div>
            <div className="label">
              {t({ id: "coupons.redeemed.subtitle" })}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
