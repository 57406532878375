import "../../styles/default-theme.css";

import { Link, useHistory } from "react-router-dom";
import React, { Fragment, Props, useEffect } from "react";

import { LevelsOverview } from "./LevelsOverview";
import { useDashboardLayoutQuery } from "../../graphql/__generated__/graphql_types";

export function DashboardLayout({ children }: Props<{}>) {
  const history = useHistory();

  useEffect(() => {
    let lastString = "";
    window.onkeypress = (evt: KeyboardEvent) => {
      const keyWord = "beershift";
      lastString += evt.key;

      if (lastString.length > keyWord.length) {
        lastString = lastString.slice(1);
      }

      if (lastString === "beershift") {
        history.push("/points-redeemer");
      }
    };

    return () => {
      window.onkeypress = null;
    };
  }, [history]);

  const { data } = useDashboardLayoutQuery();

  return (
    <Fragment>
      <div className="ui stackable padded relaxed grid dashboard">
        <div className="nav-row tablet computer only row ">
          <div
            className="ui fluid three item secondary big menu"
            style={{ width: "100%" }}
          >
            <div className=" item" style={{ justifyContent: "center" }}>
              <Link to="/dashboard">
                <img
                  className="ui image"
                  style={{
                    height: 50,
                    objectFit: "cover",
                    alignSelf: "center",
                  }}
                  src={require("../../assets/N.png")}
                  alt="Nikkie Logo"
                />
              </Link>
              <div className="ui huge black label">
                <div className="ui inverted small horizontal statistic">
                  <div className="value" style={{ fontWeight: "bold" }}>
                    {data && data.me.points}
                  </div>
                  <div
                    className="small label"
                    style={{
                      fontSize: "small",
                      textTransform: "lowercase",
                    }}
                  >
                    pts
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mobile only row">
          <div className="ui fluid three item secondary big menu">
            <div className="item">
              <Link to="/dashboard">
                <img
                  className=""
                  style={{ height: 50, objectFit: "cover", marginLeft: 15 }}
                  src={require("../../assets/N.png")}
                  alt="Nikkie Logo"
                />
              </Link>
              <div className="ui huge black label">
                <div className="ui inverted small horizontal statistic">
                  <div className="value" style={{ fontWeight: "bold" }}>
                    {data && data.me.points}
                  </div>
                  <div
                    className="small label"
                    style={{
                      fontSize: "small",
                      textTransform: "lowercase",
                    }}
                  >
                    pts
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LevelsOverview />
      <div id="dashboard" className="ui centered stackable padded relaxed grid">
        {children}
      </div>
    </Fragment>
  );
}
