import ErrorComponent from "../ErrorComponent/ErrorComponent";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import React from "react";
import { Table } from "semantic-ui-react";
import { groupBy } from "lodash";
import { useGetEventsQuery } from "../../graphql/__generated__/graphql_types";

const EventsTable = () => {
  const { data, loading, error } = useGetEventsQuery();

  if (loading) return <LoadingSpinner />;

  if (error) return <ErrorComponent error={error} />;

  const groupedByUser = groupBy(data.events, "user.email");

  return (
    <>
      <h1>Events Dashboard</h1>

      {Object.entries(groupedByUser).map(([email, events]) => (
        <>
          <p>
            Showing the last events for <strong>{email}</strong>
          </p>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>ID</Table.HeaderCell>
                {email !== "undefined" && (
                  <Table.HeaderCell>User</Table.HeaderCell>
                )}
                <Table.HeaderCell>Timestamp</Table.HeaderCell>
                <Table.HeaderCell>Data</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {events?.slice(0, 15).map((event, index) => {
                return (
                  <Table.Row key={index}>
                    <Table.Cell>{event.type}</Table.Cell>
                    <Table.Cell>{event._id}</Table.Cell>
                    {email !== "undefined" && (
                      <Table.Cell>{event.user?.email}</Table.Cell>
                    )}
                    <Table.Cell>
                      {new Date(event.timestamp).toLocaleString()}
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        display: "block",
                        maxWidth: "85vh",
                        maxHeight: "75px",
                        overflowY: "auto",
                        whiteSpace: "pre",
                      }}
                    >
                      {JSON.stringify(JSON.parse(event.data), null, 2)
                        .replace(/[{}]/g, "")
                        .trim()}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </>
      ))}
    </>
  );
};

export default EventsTable;
