import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Admin_Date: any;
  Date: any;
};

export type Query = {
  __typename?: 'Query';
  admin_events?: Maybe<Array<Maybe<Admin_Events>>>;
  admin_merchant?: Maybe<Admin_Merchant>;
  admin_cards?: Maybe<Array<Maybe<Admin_LinkedCard>>>;
  admin_users?: Maybe<Array<Maybe<Admin_User>>>;
  admin_transactions?: Maybe<Array<Maybe<Admin_Transaction>>>;
  ecomEmail?: Maybe<EcomEmail>;
  card?: Maybe<LinkedCard>;
  me?: Maybe<User>;
  merchant?: Maybe<Merchant>;
  paymentMethods: PaymentMethodResponse;
  pos_getPaymentInfo?: Maybe<Pos_PaymentInfo>;
  pos_getLoyaltyInfo?: Maybe<Pos_LoyaltyCard>;
};


export type QueryEcomEmailArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryCardArgs = {
  shopperReference: Scalars['String'];
};


export type QueryPaymentMethodsArgs = {
  amountInCent: Scalars['Int'];
  shopperReference?: Maybe<Scalars['String']>;
};


export type QueryPos_GetPaymentInfoArgs = {
  orderId: Scalars['String'];
};


export type QueryPos_GetLoyaltyInfoArgs = {
  customerKey: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  admin_deleteUser: Scalars['String'];
  admin_deleteCard: Scalars['String'];
  admin_updateMerchant?: Maybe<Admin_Merchant>;
  updateNotificationStrategy: Scalars['String'];
  register?: Maybe<User>;
  linkCardToAccount?: Maybe<Scalars['String']>;
  login?: Maybe<User>;
  redeemPoints: Scalars['String'];
  registerWithPayment: CreatePaymentResponse;
  validateAppleMerchant: ValidationResponse;
  pos_startPayment?: Maybe<Pos_TerminalResponse>;
  pos_startPaymentKoekemannetje?: Maybe<Pos_TerminalResponseAdyen>;
  pos_startPaymentAdyen?: Maybe<Pos_TerminalResponseAdyen>;
  pos_createCard?: Maybe<Pos_LoyaltyCard>;
};


export type MutationAdmin_DeleteUserArgs = {
  id?: Maybe<Scalars['String']>;
};


export type MutationAdmin_DeleteCardArgs = {
  id: Scalars['String'];
};


export type MutationAdmin_UpdateMerchantArgs = {
  levelsConfig: Scalars['String'];
  welcomePoints: Scalars['Int'];
  pointsPerCent: Scalars['Float'];
};


export type MutationUpdateNotificationStrategyArgs = {
  strategy?: Maybe<NotificationStrategy>;
  input?: Maybe<UpdateNotificationStrategyInput>;
};


export type MutationRegisterArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};


export type MutationLinkCardToAccountArgs = {
  shopperReference: Scalars['String'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRedeemPointsArgs = {
  points?: Maybe<Scalars['Int']>;
};


export type MutationRegisterWithPaymentArgs = {
  input: CreateRegisterPaymentInput;
};


export type MutationValidateAppleMerchantArgs = {
  validationURL?: Maybe<Scalars['String']>;
};


export type MutationPos_StartPaymentArgs = {
  amount: Scalars['Int'];
};


export type MutationPos_StartPaymentKoekemannetjeArgs = {
  amount: Scalars['Int'];
};


export type MutationPos_StartPaymentAdyenArgs = {
  amount: Scalars['Int'];
};


export type MutationPos_CreateCardArgs = {
  customerKey: Scalars['String'];
  hash: Scalars['String'];
  phoneNumber: Scalars['String'];
};


export type Admin_CardUser = {
  __typename?: 'Admin_CardUser';
  id: Scalars['String'];
  email: Scalars['String'];
};

export type Admin_LinkedCard = {
  __typename?: 'Admin_LinkedCard';
  id: Scalars['String'];
  shopperReference: Scalars['String'];
  paymentMethod: Scalars['String'];
  User?: Maybe<Admin_CardUser>;
  phone?: Maybe<Scalars['String']>;
  verified: Scalars['Boolean'];
  createdAt: Scalars['Admin_Date'];
  cardInfos?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  provider: Scalars['String'];
  registrationStrategy?: Maybe<Scalars['String']>;
  Transaction?: Maybe<Array<Maybe<Admin_Transaction>>>;
};

export type Admin_Card = {
  __typename?: 'Admin_Card';
  id: Scalars['String'];
  paymentMethod?: Maybe<Scalars['String']>;
  shopperReference?: Maybe<Scalars['String']>;
};

export type Admin_EcomEmail = {
  __typename?: 'Admin_EcomEmail';
  id: Scalars['String'];
  email?: Maybe<Scalars['String']>;
};

export type Admin_Coupon = {
  __typename?: 'Admin_Coupon';
  id: Scalars['String'];
};

export type Admin_Transaction = {
  __typename?: 'Admin_Transaction';
  id: Scalars['String'];
  points?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Admin_Date']>;
  note?: Maybe<Scalars['String']>;
  Card?: Maybe<Admin_Card>;
  EcomEmail?: Maybe<Admin_EcomEmail>;
};

export type Admin_User = {
  __typename?: 'Admin_User';
  id: Scalars['ID'];
  email: Scalars['String'];
  createdAt?: Maybe<Scalars['Admin_Date']>;
  name?: Maybe<Scalars['String']>;
  Card?: Maybe<Array<Maybe<Admin_Card>>>;
  EcomEmail?: Maybe<Array<Maybe<Admin_EcomEmail>>>;
  points?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  Coupon?: Maybe<Array<Maybe<Admin_Coupon>>>;
  isAdmin?: Maybe<Scalars['Boolean']>;
};

export type Admin_LevelConfig = {
  __typename?: 'Admin_LevelConfig';
  mainColor?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  discountInPercent?: Maybe<Scalars['Float']>;
};

export type Admin_Merchant = {
  __typename?: 'Admin_Merchant';
  id: Scalars['ID'];
  levelsConfig?: Maybe<Scalars['String']>;
  levelsConfigData?: Maybe<Array<Maybe<Admin_LevelConfig>>>;
  pointsPerCent?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  welcomePoints?: Maybe<Scalars['Int']>;
  merchantName?: Maybe<Scalars['String']>;
};

export type Admin_Events = {
  __typename?: 'Admin_Events';
  _id: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Admin_Date']>;
  data?: Maybe<Scalars['String']>;
  /** Do not run the points query here because it is not optimized and will create N+1 workloads as it's calculated on demand */
  user?: Maybe<Admin_User>;
};

export enum NotificationStrategy {
  Phone = 'Phone',
  Email = 'Email',
  Push = 'Push'
}

export enum CardProvider {
  Adyen = 'Adyen',
  PayNl = 'PayNL'
}

export type Transaction = {
  __typename?: 'Transaction';
  points: Scalars['Int'];
};


export type LinkedCard = {
  __typename?: 'LinkedCard';
  id: Scalars['String'];
  shopperReference: Scalars['String'];
  paymentMethod: Scalars['String'];
  user?: Maybe<Scalars['String']>;
  User?: Maybe<User>;
  phone?: Maybe<Scalars['String']>;
  verified: Scalars['Boolean'];
  createdAt: Scalars['Date'];
  cardInfos?: Maybe<Scalars['String']>;
  provider?: Maybe<CardProvider>;
  registrationStrategy?: Maybe<Scalars['String']>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
};

export type Events = {
  __typename?: 'Events';
  _id: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Date']>;
  data?: Maybe<Scalars['String']>;
};

export type Coupon = {
  __typename?: 'Coupon';
  id: Scalars['ID'];
  code?: Maybe<Scalars['String']>;
  redeemed?: Maybe<Scalars['Boolean']>;
  redeemedAt?: Maybe<Scalars['Date']>;
  revoked?: Maybe<Scalars['Boolean']>;
  orderId?: Maybe<Scalars['String']>;
  amountInCent?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type EventsCollection = {
  __typename?: 'EventsCollection';
  items?: Maybe<Array<Events>>;
  eventSet?: Maybe<Array<Scalars['String']>>;
  count: Scalars['Int'];
};


export type EventsCollectionItemsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  types?: Maybe<Array<Scalars['String']>>;
};


export type EventsCollectionCountArgs = {
  types?: Maybe<Array<Scalars['String']>>;
};

export type CouponsCollection = {
  __typename?: 'CouponsCollection';
  items?: Maybe<Array<Coupon>>;
  count: Scalars['Int'];
};


export type CouponsCollectionItemsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  redeemed?: Maybe<Scalars['Boolean']>;
};


export type CouponsCollectionCountArgs = {
  redeemed?: Maybe<Scalars['Boolean']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  email: Scalars['String'];
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  pushKey?: Maybe<Scalars['String']>;
  notificationStrategy?: Maybe<NotificationStrategy>;
  cards?: Maybe<Array<Maybe<LinkedCard>>>;
  ecomEmails?: Maybe<Array<EcomEmail>>;
  points?: Maybe<Scalars['Int']>;
  authData?: Maybe<AuthProviderData>;
  coupons?: Maybe<Array<Coupon>>;
  couponsCollection?: Maybe<CouponsCollection>;
  events?: Maybe<Array<Events>>;
  eventsCollection?: Maybe<EventsCollection>;
  isAdmin?: Maybe<Scalars['Boolean']>;
};


export type UserEventsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  types?: Maybe<Array<Scalars['String']>>;
};

export type AuthProviderData = {
  __typename?: 'AuthProviderData';
  picture?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

export type EcomEmail = {
  __typename?: 'EcomEmail';
  email: Scalars['String'];
  verified: Scalars['Boolean'];
  verifiedAt?: Maybe<Scalars['Date']>;
};

export type PaymentGroups = {
  __typename?: 'PaymentGroups';
  name: Scalars['String'];
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PaymentMethodDetailsItems = {
  __typename?: 'PaymentMethodDetailsItems';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type PaymentMethodDetails = {
  __typename?: 'PaymentMethodDetails';
  key?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<PaymentMethodDetailsItems>>>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  brands?: Maybe<Array<Maybe<Scalars['String']>>>;
  details?: Maybe<Array<Maybe<PaymentMethodDetails>>>;
  name?: Maybe<Scalars['String']>;
  supportsRecurring?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

export type PaymentMethodResponse = {
  __typename?: 'PaymentMethodResponse';
  groups?: Maybe<Array<Maybe<PaymentGroups>>>;
  paymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
};

export type LevelConfig = {
  __typename?: 'LevelConfig';
  mainColor?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  discountInPercent?: Maybe<Scalars['Float']>;
};

export type Merchant = {
  __typename?: 'Merchant';
  levelsConfig?: Maybe<Scalars['String']>;
  levelsConfigData?: Maybe<Array<LevelConfig>>;
  pointsPerCent?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  welcomePoints?: Maybe<Scalars['Int']>;
  merchantName?: Maybe<Scalars['String']>;
};

export type PaymentMethodInput = {
  type?: Maybe<Scalars['String']>;
  applepayToken?: Maybe<Scalars['String']>;
  issuer?: Maybe<Scalars['String']>;
  schema?: Maybe<Scalars['String']>;
  encryptedCardNumber?: Maybe<Scalars['String']>;
  encryptedExpiryMonth?: Maybe<Scalars['String']>;
  encryptedExpiryYear?: Maybe<Scalars['String']>;
  encryptedSecurityCode?: Maybe<Scalars['String']>;
};

export type CreateRegisterPaymentInput = {
  paymentMethod: PaymentMethodInput;
  returnURL: Scalars['String'];
};

export type CreatePaymentInput = {
  shopperReference: Scalars['String'];
  amountInCent: Scalars['Int'];
  paymentMethod: PaymentMethodInput;
  returnUrl: Scalars['String'];
};

export type CreateUserInput = {
  email?: Maybe<Scalars['String']>;
  shopperReference: Scalars['String'];
  userToLink?: Maybe<Scalars['String']>;
};

export type CreatePaymentResponse = {
  __typename?: 'CreatePaymentResponse';
  redirectURL?: Maybe<Scalars['String']>;
  resultCode: Scalars['String'];
  shopperReference?: Maybe<Scalars['String']>;
};

export type ValidationResponse = {
  __typename?: 'ValidationResponse';
  epochTimestamp: Scalars['String'];
  expiresAt: Scalars['String'];
  merchantSessionIdentifier: Scalars['String'];
  nonce: Scalars['String'];
  merchantIdentifier: Scalars['String'];
  domainName: Scalars['String'];
  displayName: Scalars['String'];
  signature: Scalars['String'];
};

export type UpdateNotificationStrategyInput = {
  pushKey?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type Pos_PaymentInfo = {
  __typename?: 'POS_PaymentInfo';
  id: Scalars['String'];
  orderId: Scalars['String'];
  customerKey: Scalars['String'];
  data?: Maybe<Scalars['String']>;
};

export type Pos_User = {
  __typename?: 'POS_User';
  name: Scalars['String'];
  email: Scalars['String'];
};

export type Pos_LoyaltyCard = {
  __typename?: 'POS_LoyaltyCard';
  id: Scalars['String'];
  shopperReference: Scalars['String'];
  User?: Maybe<Pos_User>;
};

export type Pos_TerminalResponse = {
  __typename?: 'POS_TerminalResponse';
  transactionId: Scalars['String'];
  hash: Scalars['String'];
};

export type Pos_TerminalResponseAdyen = {
  __typename?: 'POS_TerminalResponseAdyen';
  payment: Scalars['String'];
};

export type GetCardsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCardsQuery = (
  { __typename?: 'Query' }
  & { cards?: Maybe<Array<Maybe<(
    { __typename?: 'Admin_LinkedCard' }
    & Pick<Admin_LinkedCard, 'id' | 'shopperReference' | 'paymentMethod' | 'createdAt' | 'verified' | 'cardInfos' | 'registrationStrategy' | 'provider'>
    & { user?: Maybe<(
      { __typename?: 'Admin_CardUser' }
      & Pick<Admin_CardUser, 'email'>
    )>, transactions?: Maybe<Array<Maybe<(
      { __typename?: 'Admin_Transaction' }
      & Pick<Admin_Transaction, 'points'>
    )>>> }
  )>>> }
);

export type DeleteCardMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteCardMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'admin_deleteCard'>
);

export type GetEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEventsQuery = (
  { __typename?: 'Query' }
  & { events?: Maybe<Array<Maybe<(
    { __typename?: 'Admin_Events' }
    & Pick<Admin_Events, '_id' | 'data' | 'type' | 'timestamp'>
    & { user?: Maybe<(
      { __typename?: 'Admin_User' }
      & Pick<Admin_User, 'email'>
    )> }
  )>>> }
);

export type GetMerchantConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMerchantConfigQuery = (
  { __typename?: 'Query' }
  & { merchant?: Maybe<(
    { __typename?: 'Admin_Merchant' }
    & Pick<Admin_Merchant, 'id' | 'name' | 'pointsPerCent' | 'welcomePoints' | 'merchantName'>
    & { levelsConfig?: Maybe<Array<Maybe<(
      { __typename?: 'Admin_LevelConfig' }
      & Pick<Admin_LevelConfig, 'name' | 'from' | 'to' | 'discountInPercent' | 'mainColor'>
    )>>> }
  )> }
);

export type UpdateMerchantConfigMutationVariables = Exact<{
  levelsConfig: Scalars['String'];
  welcomePoints: Scalars['Int'];
  pointsPerCent: Scalars['Float'];
}>;


export type UpdateMerchantConfigMutation = (
  { __typename?: 'Mutation' }
  & { admin_updateMerchant?: Maybe<(
    { __typename?: 'Admin_Merchant' }
    & Pick<Admin_Merchant, 'name' | 'pointsPerCent' | 'welcomePoints' | 'merchantName'>
    & { levelsConfig?: Maybe<Array<Maybe<(
      { __typename?: 'Admin_LevelConfig' }
      & Pick<Admin_LevelConfig, 'name' | 'from' | 'to' | 'discountInPercent' | 'mainColor'>
    )>>> }
  )> }
);

export type GetTransactionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTransactionsQuery = (
  { __typename?: 'Query' }
  & { transactions?: Maybe<Array<Maybe<(
    { __typename?: 'Admin_Transaction' }
    & Pick<Admin_Transaction, 'id' | 'points' | 'createdAt' | 'note'>
    & { card?: Maybe<(
      { __typename?: 'Admin_Card' }
      & Pick<Admin_Card, 'paymentMethod'>
    )>, ecomEmail?: Maybe<(
      { __typename?: 'Admin_EcomEmail' }
      & Pick<Admin_EcomEmail, 'email'>
    )> }
  )>>> }
);

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<Array<Maybe<(
    { __typename?: 'Admin_User' }
    & Pick<Admin_User, 'email' | 'id' | 'createdAt' | 'name' | 'phone'>
    & { cards?: Maybe<Array<Maybe<(
      { __typename?: 'Admin_Card' }
      & Pick<Admin_Card, 'shopperReference'>
    )>>>, ecomEmails?: Maybe<Array<Maybe<(
      { __typename?: 'Admin_EcomEmail' }
      & Pick<Admin_EcomEmail, 'email'>
    )>>> }
  )>>> }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'admin_deleteUser'>
);

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'isAdmin'>
  )> }
);


export const GetCardsDocument = gql`
    query getCards {
  cards: admin_cards {
    id
    shopperReference
    paymentMethod
    createdAt
    verified
    cardInfos
    registrationStrategy
    provider
    user: User {
      email
    }
    transactions: Transaction {
      points
    }
  }
}
    `;

/**
 * __useGetCardsQuery__
 *
 * To run a query within a React component, call `useGetCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCardsQuery(baseOptions?: Apollo.QueryHookOptions<GetCardsQuery, GetCardsQueryVariables>) {
        return Apollo.useQuery<GetCardsQuery, GetCardsQueryVariables>(GetCardsDocument, baseOptions);
      }
export function useGetCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardsQuery, GetCardsQueryVariables>) {
          return Apollo.useLazyQuery<GetCardsQuery, GetCardsQueryVariables>(GetCardsDocument, baseOptions);
        }
export type GetCardsQueryHookResult = ReturnType<typeof useGetCardsQuery>;
export type GetCardsLazyQueryHookResult = ReturnType<typeof useGetCardsLazyQuery>;
export type GetCardsQueryResult = Apollo.QueryResult<GetCardsQuery, GetCardsQueryVariables>;
export const DeleteCardDocument = gql`
    mutation deleteCard($id: String!) {
  admin_deleteCard(id: $id)
}
    `;
export type DeleteCardMutationFn = Apollo.MutationFunction<DeleteCardMutation, DeleteCardMutationVariables>;

/**
 * __useDeleteCardMutation__
 *
 * To run a mutation, you first call `useDeleteCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCardMutation, { data, loading, error }] = useDeleteCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCardMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCardMutation, DeleteCardMutationVariables>) {
        return Apollo.useMutation<DeleteCardMutation, DeleteCardMutationVariables>(DeleteCardDocument, baseOptions);
      }
export type DeleteCardMutationHookResult = ReturnType<typeof useDeleteCardMutation>;
export type DeleteCardMutationResult = Apollo.MutationResult<DeleteCardMutation>;
export type DeleteCardMutationOptions = Apollo.BaseMutationOptions<DeleteCardMutation, DeleteCardMutationVariables>;
export const GetEventsDocument = gql`
    query getEvents {
  events: admin_events {
    _id
    data
    type
    timestamp
    user {
      email
    }
  }
}
    `;

/**
 * __useGetEventsQuery__
 *
 * To run a query within a React component, call `useGetEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEventsQuery(baseOptions?: Apollo.QueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
        return Apollo.useQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, baseOptions);
      }
export function useGetEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
          return Apollo.useLazyQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, baseOptions);
        }
export type GetEventsQueryHookResult = ReturnType<typeof useGetEventsQuery>;
export type GetEventsLazyQueryHookResult = ReturnType<typeof useGetEventsLazyQuery>;
export type GetEventsQueryResult = Apollo.QueryResult<GetEventsQuery, GetEventsQueryVariables>;
export const GetMerchantConfigDocument = gql`
    query getMerchantConfig {
  merchant: admin_merchant {
    id
    name
    pointsPerCent
    welcomePoints
    merchantName
    levelsConfig: levelsConfigData {
      name
      from
      to
      discountInPercent
      mainColor
    }
  }
}
    `;

/**
 * __useGetMerchantConfigQuery__
 *
 * To run a query within a React component, call `useGetMerchantConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMerchantConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMerchantConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMerchantConfigQuery(baseOptions?: Apollo.QueryHookOptions<GetMerchantConfigQuery, GetMerchantConfigQueryVariables>) {
        return Apollo.useQuery<GetMerchantConfigQuery, GetMerchantConfigQueryVariables>(GetMerchantConfigDocument, baseOptions);
      }
export function useGetMerchantConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMerchantConfigQuery, GetMerchantConfigQueryVariables>) {
          return Apollo.useLazyQuery<GetMerchantConfigQuery, GetMerchantConfigQueryVariables>(GetMerchantConfigDocument, baseOptions);
        }
export type GetMerchantConfigQueryHookResult = ReturnType<typeof useGetMerchantConfigQuery>;
export type GetMerchantConfigLazyQueryHookResult = ReturnType<typeof useGetMerchantConfigLazyQuery>;
export type GetMerchantConfigQueryResult = Apollo.QueryResult<GetMerchantConfigQuery, GetMerchantConfigQueryVariables>;
export const UpdateMerchantConfigDocument = gql`
    mutation updateMerchantConfig($levelsConfig: String!, $welcomePoints: Int!, $pointsPerCent: Float!) {
  admin_updateMerchant(levelsConfig: $levelsConfig, welcomePoints: $welcomePoints, pointsPerCent: $pointsPerCent) {
    name
    pointsPerCent
    welcomePoints
    merchantName
    levelsConfig: levelsConfigData {
      name
      from
      to
      discountInPercent
      mainColor
    }
  }
}
    `;
export type UpdateMerchantConfigMutationFn = Apollo.MutationFunction<UpdateMerchantConfigMutation, UpdateMerchantConfigMutationVariables>;

/**
 * __useUpdateMerchantConfigMutation__
 *
 * To run a mutation, you first call `useUpdateMerchantConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMerchantConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMerchantConfigMutation, { data, loading, error }] = useUpdateMerchantConfigMutation({
 *   variables: {
 *      levelsConfig: // value for 'levelsConfig'
 *      welcomePoints: // value for 'welcomePoints'
 *      pointsPerCent: // value for 'pointsPerCent'
 *   },
 * });
 */
export function useUpdateMerchantConfigMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMerchantConfigMutation, UpdateMerchantConfigMutationVariables>) {
        return Apollo.useMutation<UpdateMerchantConfigMutation, UpdateMerchantConfigMutationVariables>(UpdateMerchantConfigDocument, baseOptions);
      }
export type UpdateMerchantConfigMutationHookResult = ReturnType<typeof useUpdateMerchantConfigMutation>;
export type UpdateMerchantConfigMutationResult = Apollo.MutationResult<UpdateMerchantConfigMutation>;
export type UpdateMerchantConfigMutationOptions = Apollo.BaseMutationOptions<UpdateMerchantConfigMutation, UpdateMerchantConfigMutationVariables>;
export const GetTransactionsDocument = gql`
    query getTransactions {
  transactions: admin_transactions {
    id
    points
    createdAt
    note
    card: Card {
      paymentMethod
    }
    ecomEmail: EcomEmail {
      email
    }
  }
}
    `;

/**
 * __useGetTransactionsQuery__
 *
 * To run a query within a React component, call `useGetTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>) {
        return Apollo.useQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, baseOptions);
      }
export function useGetTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>) {
          return Apollo.useLazyQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, baseOptions);
        }
export type GetTransactionsQueryHookResult = ReturnType<typeof useGetTransactionsQuery>;
export type GetTransactionsLazyQueryHookResult = ReturnType<typeof useGetTransactionsLazyQuery>;
export type GetTransactionsQueryResult = Apollo.QueryResult<GetTransactionsQuery, GetTransactionsQueryVariables>;
export const GetUserDocument = gql`
    query getUser {
  users: admin_users {
    email
    id
    createdAt
    name
    phone
    cards: Card {
      shopperReference
    }
    ecomEmails: EcomEmail {
      email
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: String!) {
  admin_deleteUser(id: $id)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, baseOptions);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const UserDocument = gql`
    query user {
  me {
    isAdmin
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;

      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": []
  }
};
      export default result;
    
// Generated on 01-09-2021 @ 16:35:50
