import React, { useEffect, useState } from "react";

import { useCheckCardQuery } from "../graphql/__generated__/graphql_types";
import { useHistory } from "react-router-dom";

const timeUntilDashboardButton = 30000;

export function AwaitCard() {
  const params = new URLSearchParams(window.location.search);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > timeUntilDashboardButton) {
        clearInterval(interval);
        return;
      }

      setTimer((timer) => timer + 500);
    }, 500);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  const { data } = useCheckCardQuery({
    skip: !params.get("shopperReference"),
    pollInterval: 5000,
    variables: {
      shopperReference: params.get("shopperReference"),
    },
  });

  const history = useHistory();

  function proceed() {
    history.push("/dashboard");
  }

  if (!params.get("shopperReference")) {
    return <div>This site needs a shopper reference</div>;
  }

  const activateDashboardButton = timer > timeUntilDashboardButton;

  const paymentType = params.get("paymentType");

  return (
    <div className="row">
      <div className="eight wide column">
        {!data?.card?.verified && (
          <div className="text-center">
            {!activateDashboardButton ? (
              <div>
                Jouw {paymentType === "ideal" && <span>pin pass</span>}
                {paymentType === "card" && <span>kredietkaart</span>}
                {paymentType === "applepay" && <span>apple pay</span>} wordt nu
                gekoppeld. Dat kan even duren (tot 2 min).
                <br />
                <br />
                <div className="ui active inline centered loader"></div>
                <br />
                <br />
              </div>
            ) : (
              <div>
                <p>
                  <i className="big icon exclamation circle"></i>
                </p>
                De activering lijkt wat langer te duren. U kunt doorgaan naar
                het overzicht. De activering gaat door op de achtergrond
                <br />
                <button
                  className="ui primary button"
                  onClick={proceed}
                  style={{ marginTop: 20 }}
                >
                  Ga naar overzicht
                </button>
              </div>
            )}
          </div>
        )}
        {data?.card?.verified && (
          <div className="text-center">
            Gelukt! Je spaart nu automatisch bij Nikkie in de winkel en op one
            website. Je ontvangt een kortingscoupon wanneer je genoeg punten
            hebt ontvangen!
            <br />
            <button
              className="ui primary button"
              onClick={proceed}
              style={{ marginTop: 20 }}
            >
              Ga naar overzicht
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
