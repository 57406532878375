import React, { useEffect } from "react";

import { range } from "lodash";
import { useIntl } from "react-intl";
import { useLevelQuery } from "../../graphql/__generated__/graphql_types";
import { useLiveRefresh } from "../services/EventStreamContext";

interface LevelConfig {
  mainColor: string;
  from: number;
  to: number;
  name: string;
  discountInPercent: number;
}

function calculateMissingPoints(level: LevelConfig, points: number) {
  const missingPoints = level.from - points;
  if (missingPoints > 0) {
    return missingPoints;
  }
  return null;
}

export function LevelsOverview() {
  const { formatMessage: t } = useIntl();

  const { data, loading, refetch } = useLevelQuery();

  useLiveRefresh(refetch);

  const levelsConfig: LevelConfig[] = data
    ? JSON.parse(data.merchant.levelsConfig)
    : [];

  const currentLevel: number | number = data
    ? levelsConfig.findIndex(
        (level) => data.me.points >= level.from && data.me.points <= level.to
      )
    : null;

  useEffect(() => {
    document.querySelector(".card.active")?.scrollIntoView(false);
  }, [currentLevel]);

  if (loading) {
    return <div className="ui active inline centered loader"></div>;
  }

  if (!data) {
    return null;
  }

  return (
    <div className="ui centered stackable padded relaxed grid dashboard">
      <div className="row levels-overview background-levels">
        <div>
          <div className="levels-array-display">
            {levelsConfig &&
              levelsConfig.map((level, index: number) => (
                <div key={level.name} style={{ margin: "15px" }}>
                  <div>
                    {calculateMissingPoints(level, data.me.points) ? (
                      <div
                        style={{
                          color: level.mainColor,
                          textAlign: "right",
                        }}
                      >
                        {t(
                          { id: "levelsOverview.missingPoints" },
                          {
                            missingPoints: calculateMissingPoints(
                              level,
                              data.me.points
                            ),
                            missingAmount:
                              calculateMissingPoints(level, data.me.points) /
                              data.merchant.pointsPerCent /
                              100,
                            level: index + 1,
                          }
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          color: level.mainColor,
                          textAlign: "right",
                        }}
                      >
                        nog 100 (5€) voor niveau {index + 1}
                      </div>
                    )}
                  </div>

                  <div
                    className={`ui ${
                      currentLevel === index ? "active" : ""
                    } card`}
                    style={{ borderRadius: 0 }}
                  >
                    <div
                      className="content"
                      style={{
                        height: 125,
                        background: level.mainColor,
                        textAlign: "center",
                        borderRadius: "0!important",
                      }}
                    >
                      {currentLevel === index && (
                        <div
                          className="ui red ribbon label"
                          style={{ position: "absolute", left: -14.5, top: 7 }}
                        >
                          {t({ id: "levelsOverview.yourLevel" })}
                        </div>
                      )}

                      <div style={{ marginTop: 25 }}>
                        <div className="loyalty-level">
                          {range(index + 1).map((index) => (
                            <img
                              key={index}
                              src={require("../../assets/level1.png")}
                              alt="loyalty level logo"
                            />
                          ))}
                        </div>
                        <div style={{ fontSize: "1.5rem", marginTop: 5 }}>
                          {level.from}—{level.to}pt
                        </div>
                      </div>
                    </div>
                    <div
                      className="content"
                      style={{
                        textAlign: "center",
                        fontSize: "large",
                        color: level.mainColor,
                        padding: 10,
                      }}
                    >
                      {t(
                        { id: "levelsOverview.coupon.title" },
                        { couponValue: level.discountInPercent }
                      )}
                    </div>
                    <div
                      className="extra content"
                      style={{
                        textAlign: "center",
                        hyphens: "auto",
                      }}
                    >
                      {t(
                        { id: "levelsOverview.coupon.description" },
                        { couponValue: `${level.discountInPercent * 100}%` }
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
