import React, { useEffect, useState } from "react";
import {
  useNotificationStrategyMutation,
  useSettingsQuery,
} from "../graphql/__generated__/graphql_types";

import { NotificationStrategy } from "../__generated__/globalTypes";
import { useIntl } from "react-intl";

export function SettingsPage() {
  const { formatMessage: t } = useIntl();

  const [state, setState] = useState<{
    notificationStrategy: NotificationStrategy;
  }>({
    notificationStrategy: NotificationStrategy.Email,
  });
  const { data, loading } = useSettingsQuery();

  const [mutate, { loading: updating }] = useNotificationStrategyMutation();

  useEffect(() => {
    if (data) {
      setState((state) => ({
        ...state,
        notificationStrategy: data.me.notificationStrategy,
      }));
    }
  }, [data]);

  function updateNotificationStrategy() {
    mutate({ variables: { strategy: state.notificationStrategy } });
  }

  if (loading) {
    return <div v-if="loading" className="ui active loader"></div>;
  }

  if (!data) {
    return null;
  }

  return (
    <div v-if="profile" className="row padded-row">
      <div className="five wide column">
        <h3 className="ui header">
          <i className="large cog icon"></i>
          <div className="content">{t({ id: "settings.title" })}</div>
        </h3>
        <div className="ui list">
          <div className="ui form">
            <div className="grouped fields" style={{ width: 400 }}>
              <label htmlFor="notification-option">
                {t({ id: "settings.text" })}
              </label>
              <div className="field">
                <div className="ui radio checkbox">
                  <input
                    id="option-all"
                    v-model="notificationOption"
                    type="radio"
                    name="notification-option"
                    value="Email"
                    checked={
                      state.notificationStrategy === NotificationStrategy.Email
                    }
                    onChange={() =>
                      setState({
                        ...state,
                        notificationStrategy: NotificationStrategy.Email,
                      })
                    }
                  />
                  <label htmlFor="option-all">Email</label>
                  <div style={{ marginLeft: 26 }}>
                    <small>
                      {t({ id: "settings.email" })}
                      <strong>{data.me.email}</strong>
                    </small>
                  </div>
                </div>
              </div>

              <div className="ui radio checkbox">
                <input
                  id="option-ecom"
                  v-model="notificationOption"
                  type="radio"
                  name="notification-option"
                  value="Phone"
                  checked={
                    state.notificationStrategy === NotificationStrategy.Phone
                  }
                  onChange={() =>
                    setState({
                      ...state,
                      notificationStrategy: NotificationStrategy.Phone,
                    })
                  }
                />
                <label htmlFor="option-ecom">SMS</label>
                <div
                  v-if="notificationOption === 'Phone'"
                  className=""
                  style={{ marginLeft: 25 }}
                >
                  <small>
                    {t({ id: "settings.sms" })}
                    <strong>{data.me.phone}</strong>
                  </small>
                </div>
              </div>

              <br />
              <button
                className={`ui ${updating ? "loading" : ""} button primary`}
                style={{ marginTop: 20 }}
                onClick={updateNotificationStrategy}
              >
                {t({ id: "settings.save" })}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
